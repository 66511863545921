import styles from "../css/app.pcss";
import { tns } from "tiny-slider/src/tiny-slider";
var SimpleLightbox = require("simple-lightbox");
import SmoothScroll from "smooth-scroll/dist/smooth-scroll.js";
const scroll = new SmoothScroll("[data-scroll]");
// App main
const main = async () => {
  // Import our CSS
  //const Styles = await import(/* webpackChunkName: "styles" */ '../css/app.pcss');
  // Async load the vue module
  // const Vue = await import(/* webpackChunkName: "vue" */ 'vue');
  // Create our vue instance
  // const vm = new Vue.default({
  //     el: "#app",
  //     components: {
  //         'confetti': () => import(/* webpackChunkName: "confetti" */ '../vue/Confetti.vue'),
  //     },
  //     data: {
  //     },
  //     methods: {
  //     },
  //     mounted() {
  //     },
  // });
};
// Execute async function
main().then(value => {
  document.getElementById("hamburger").onclick = function() {
    document.getElementById("navbar").classList.toggle("opened");
    document.getElementById("hamburger").classList.toggle("open");
  };

  document.querySelectorAll('.nav-item').forEach(item => {
		item.addEventListener('click', event => {
			document.getElementById("navbar").classList.remove("opened");
			document.getElementById("hamburger").classList.remove("open");
		})
	})

  const infoButton = document.querySelectorAll(".info-button");
  infoButton.forEach(el => {
    el.onclick = function() {
      el.parentElement.querySelector("div.info-block").classList.toggle("opened")
    };
  })
  const infoButtonClose = document.querySelectorAll(".info-block-close");
  infoButtonClose.forEach(el => {
    el.onclick = function() {
      el.parentElement.parentElement.classList.toggle("opened")
    };
  })
  const peopleThumb = document.querySelectorAll(".people-thumb");
  peopleThumb.forEach(el => {
    el.onclick = function() {
      el.parentElement.parentElement.parentElement.classList.toggle("opened")
    };
  })
  
  const latestToggle = document.querySelectorAll(".latesttog");
  latestToggle.forEach(el => {
    el.onclick = function() {
      el.classList.toggle("opened")
      el.parentElement.querySelector("div.latestwork-info").classList.toggle("opened")
    };
  })

  

  var lightbox = new SimpleLightbox({ elements: ".gallery .gallery-image, .video-gallery" });

  var sliderArray = document.querySelectorAll(".banner-slider");
  sliderArray.forEach(function(el) {
    tns({
      container: el,
      mode: "gallery",
      mouseDrag: true,
      controls: true,
      prevButton: el.parentElement.querySelector("div.prev-button"),
      nextButton: el.parentElement.querySelector("div.next-button"),
      nav: false,
      items: 1,
      autoplay: true,
      autoplayButtonOutput: false,
      lazyload: true,
      speed: "600"


    });
  });

  var peopleArray = document.querySelectorAll(".people-slider");
  peopleArray.forEach(function(el) {
    tns({
      container: el,
      mode: "gallery",
      mouseDrag: true,
      controls: true,
      prevButton: el.parentElement.querySelector("div.prev-button"),
      nextButton: el.parentElement.querySelector("div.next-button"),
      items: 1,
      navAsThumbnails: true,
      navContainer: el.parentElement.querySelector("div.people-thumbs"),
      lazyload: true,
      speed: "600"

    });
  });
  
  var facilityArray = document.querySelectorAll(".facility-slider");
  facilityArray.forEach(function(el) {
    tns({
      container: el,
      // mode: "gallery",
      mouseDrag: true,
      controls: true,
      prevButton: el.parentElement.querySelector("div.prev-button"),
      nextButton: el.parentElement.querySelector("div.next-button"),
      nav: false,
      navPosition: "bottom",
      items: 1,
      autoplay: false,
      autoplayButtonOutput: false,
      lazyload: true,
      speed: "600"


    });
  });
  

  var imageArray = document.querySelectorAll(".facility-image-slider");
  imageArray.forEach(function(el) {
    tns({
      container: el,
      mode: "gallery",
      mouseDrag: true,
      controls: false,
      nav: false,
      navPosition: "bottom",
      items: 1,
      autoplay: true,
      autoplayButtonOutput: false,
      lazyload: true,
      speed: "600"


    });
  });
  
  var imageArray = document.querySelectorAll(".image-slider");
  imageArray.forEach(function(el) {
    tns({
      container: el,
      mode: "gallery",
      mouseDrag: true,
      controls: false,
      nav: false,
      navPosition: "bottom",
      items: 1,
      autoplay: true,
      autoplayButtonOutput: false,
      lazyload: true,
      speed: "600"


    });
  });

  var scroll = new SmoothScroll('a[href*="#"]');


});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}
